import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap'
import helper from 'services/helper'
import request from 'services/request'

const PaymentSeller = (props) => {
  const [tableData, setTableData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [currentDataRefund, setCurrentDataRefund] = useState({})
  useEffect(() => {
    getTable()
  }, [])

  const getTable = async () => {
    try {
      let rs = await request.get('/merchant/queryTransaction')
      if (rs?.error === 0) {
        setTableData(rs.data || [])
      } else {
        helper.toast('error', rs?.message && 'Error')
        setTableData([])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefund = async (dataRefundRequest) => {
    let rs = await request.post('/merchant/requestRefundPayment', dataRefundRequest)
    if (rs?.err === 200) {
      helper.toast('success', 'Refund success')
    } else {
      helper.toast('error', rs?.message ? rs?.message : 'Refund fail')
    }
    setIsOpen(false)
    getTable()
  }
  return (
    <>
      <Card style={{ margin: '30px 20px 0 10px', minHeight: '500px' }} className='shadow'>
        <CardHeader>
          <h4>Transaction History</h4>
        </CardHeader>
        <CardBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                {table.map((tb, key) => (
                  <th key={key}>{tb.header}</th>
                ))}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData
                .filter((d) => d.status !== 'refunded')
                .map((data, key) => (
                  <tr key={key}>
                    {table.map((tb, k) => (
                      <td key={k}>{data[tb.field]}</td>
                    ))}
                    <td>
                      {!data.isRefund && (
                        <Button
                          color='success'
                          onClick={() => {
                            setCurrentDataRefund({
                              transRefId: data.transRefId,
                              partnerRefId: data.partnerRefId,
                            })
                            setIsOpen(true)
                          }}
                        >
                          Refund
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} centered>
        <ModalHeader>Refund Confirmation</ModalHeader>
        <ModalBody>Do you agree to refund?</ModalBody>
        <ModalFooter>
          <Button
            color='success'
            onClick={() => {
              handleRefund(currentDataRefund)
            }}
          >
            Agree
          </Button>
          <Button
            color='danger'
            onClick={() => {
              setIsOpen(false)
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const table = [
  {
    header: 'TransRefId',
    field: 'transRefId',
  },
  {
    header: 'Sender Name',
    field: 'senderName',
  },
  {
    header: 'Sender Phone',
    field: 'senderPhone',
  },
  {
    header: 'Sender Amount',
    field: 'senderAmount',
  },
  {
    header: 'Receiver Amount',
    field: 'receiverAmount',
  },
  {
    header: 'Receiver Name',
    field: 'receiverName',
  },
  {
    header: 'Receiver Phone',
    field: 'receiverPhone',
  },
]

const exampleData = [
  {
    transRefId: '624ac410f7f3c3f1e4a5fd0e',
    senderName: 'HA CUSTOMER',
    senderPhone: '09304549445',
    senderAmount: 3000,
    receiverAmount: 3000,
    isRefund: false,
    transactionDate: '2022-04-04T10:10:24.177Z',
    receiverName: 'MERCHANR WALLET TOKEN',
    receiverPhone: '0999000123',
    partnerRefId: '624429517c9c4b0295a5c121',
    service: '61a9e265b4b2b47860e23b16',
    serviceName: 'Payment wallet token',
    serviceCode: 'PAYMENTWALLETTOKEN',
    status: 'done',
    processedByName: 'HA CUSTOMER',
    processedByPhone: '09304549445',
  },
]

export default PaymentSeller
