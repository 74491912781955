import { useEffect, useState } from 'react'
import request from '../../../../../services/request'
import '../../style/warehouseDetail.scss'
import { Link } from 'react-router-dom'
import toast from '../../../../../libs/Toast/Toast'
function WarehouseDetail(props) {
  useEffect(async () => {
    const res=await request.get(`/api/warehouse/detail?id=${props.match.params.id}`)
    setWarehouse(res.warehouse);
  }, [])
  const [warehouse,setWarehouse]=useState({});
  const exportProduct = async (id) => {
    const res=await request.post("/api/warehouse/export",{
      id
    })
    if (res){
      toast.show("success","Xuất thành công!")
      let arr;
      arr={...warehouse};
      arr.products.map((value,i)=>{
        if (value.id===id){
          value.export=true;
        }
      })
      setWarehouse(arr);
    }
  }
  const handleDeleteProduct = async (id) => {
    const res = await request.delete('/products',{id:id});
    if (res){
      toast.show("success","Delete product successfully");
      let arr;
      arr={...warehouse};
      arr.products.map((value,i)=>{
        if (value.id===id){
         arr.products.splice(i,1);
        }
      })
      setWarehouse(arr);
    }
  }
  return (
    <div id={"warehouse-detail"} className="container">
      <div className=" d-flex justify-content-center">
        <h2>WELCOME TO CAME WAREHOUSE {warehouse.name}!</h2>
      </div>
      <div>
        <div style={{marginBottom:"20px"}} className="btn-nav">
          <Link to={`/seller/home-center/warehouse`}>Back</Link>
          <Link to={`/seller/home-center/product/add/ware/${warehouse.id}`}>Import goods</Link>
        </div>
        <div>
         <p>List of products in stock</p>
          {(warehouse.products&&warehouse.products.length!==0)?warehouse.products.map((value,i)=>{
            return(
              <div className="product d-flex align-items-center justify-content-between">
               <div className="d-flex align-items-center">
                 <div className="img-product">
                   {value.imageUrl?<img src={value.imageUrl}/>:<div/>}
                 </div>
                 <div className="info">
                   <div className="name">
                     <p>{value.name}</p>
                   </div>
                   <div className="price">
                     <p>Price:{value.price}</p>
                   </div>
                   <div>
                     {value.export?<p style={{color:"#1b9e3e"}}>Has shipped </p>:<p style={{color:"#0d6efd"}}>Not yet shipped</p>}
                   </div>
                 </div>
               </div>
                <div className="btn-nav d-flex">
                  <button onClick={()=>handleDeleteProduct(value.id)}>Delete</button>
                  <button>Edit</button>
                  <button onClick={()=>exportProduct(value.id)} disabled={value.export}>Deliver</button>
                </div>
              </div>
            )
          }):<div>No products!</div>}
        </div>

      </div>
    </div>
  )
}

export default WarehouseDetail