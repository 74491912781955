import React from 'react'

function BuyerLayoutFooter() {
  return (
    <footer className='h-footer' style={{marginTop:"20px"}}>
      <div className='h-footer-container' style={{height: "150px"}}>
        <div>E commerce</div>
      </div>
    </footer>
  )
}

export default BuyerLayoutFooter
