import { Link } from 'react-router-dom'
import '../style/profileShopStyle.scss'
function ProfileShop(props) {
  return (
    <div id='profile-shop' className='d-flex flex-column justify-content-center align-items-center'>
      <Link to='/'>
        <div className='image-shop d-flex justify-content-center align-items-center'>
          {props.shop.img ? <img src={props.shop.img} /> : <i className='fas fa-user' />}
        </div>
      </Link>
      <div className='name w-100 text-center'>
        <h2>{props.shop?.name}</h2>
        <div className='rank'>
          {/* <p>Shop chua co rank</p> */}
        </div>
      </div>
    </div>
  )
}
export default ProfileShop
