import { useEffect, useState } from 'react'
import request from '../../../../../services/request'
import '../../style/productList.scss'
import toast from '../../../../../libs/Toast/Toast'
import helper from '../../../../../services/helper'

function ProductList() {
  useEffect(async () => {
    if (products.length === 0) {
      const res = await request.get('/products')
      if (res.products) setProducts(res.products)
    }
  }, [])
  const [products, setProducts] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [idDelete, setIdDelete] = useState(0)
  const [productEdit, setProductEdit] = useState({})
  const handleDelete = (id) => {
    setIdDelete(id)
    setIsDelete(true)
  }
  const handleEdit = (product) => {
    setProductEdit(product)
    setIsEdit(true)
  }
  const handleChanValue = (e, type) => {
    if (type === 'name') {
      let product = { ...productEdit }
      product.name = e.currentTarget.value
      setProductEdit(product)
    } else {
      let product = { ...productEdit }
      product.price = e.currentTarget.value
      setProductEdit(product)
    }
  }
  const handleDeleteProduct = async () => {
    const res = await request.delete('//product', { id: idDelete })
    if (res.error === 0) {
      toast.show('success', 'Delete product successfully')
      setIsDelete(false)
      let productClone = [...products]
      productClone.map((value, i) => {
        if (value.id === idDelete) {
          productClone.splice(i, 1)
        }
      })
      setProducts(productClone)
    }
  }
  const handleEditProduct = async () => {
    const res = await request.put('//product/update', {
      id: productEdit.id,
      name: productEdit.name,
      price: productEdit.price,
    })
    if (res.error === 0) {
      helper.toast('success', 'Edit successfully!')
      setIsEdit(false)
      let arr = [...products]
      products.map((value, i) => {
        if (value.id === productEdit.id) {
          arr[i].name = productEdit.name
          arr[i].price = productEdit.price
        }
      })
      setProducts(arr)
    }
  }
  if (products.length !== 0) {
    return (
      <div className='d-flex justify-content-center'>
        <div id='product-list' className='position-relative'>
          <div className='title d-flex justify-content-between'>
            <div className='name-price d-flex'>
              <p style={{ marginRight: '240px' }}>Product</p>
              <p>Price</p>
            </div>
            <div className='navigator'>
              <p>Functions</p>
            </div>
          </div>
          {products.map((value, i) => {
            return (
              <div className='product d-flex justify-content-between'>
                <div className='d-flex'>
                  <div className='image-product'>
                    {value.imageUrl ? (
                      <img src={process.env.REACT_APP_BE_URL + value?.image?.url} />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='name-product'>
                    {value.name}
                    {value.wareHouse ? <p>Kho:{value.wareHouse.name}</p> : ''}
                  </div>
                  <div className='price'>{value.price}đ</div>
                </div>
                <div className='btn-nav d-flex'>
                  <button onClick={() => handleEdit(value)}>Edit</button>
                  <button onClick={() => handleDelete(value.id)}>Delete</button>
                </div>
              </div>
            )
          })}
        </div>
        {isDelete && (
          <div>
            <div className='nen' onClick={() => setIsDelete(false)} />
            <div className='confirm-delete position-absolute'>
              <h2>Are you sure you want to delete this product?</h2>
              <div className='btn-confirm d-flex justify-content-center'>
                <button onClick={() => handleDeleteProduct()}>Yes</button>
                <button onClick={() => setIsDelete(false)}>No</button>
              </div>
            </div>
          </div>
        )}
        {isEdit && (
          <div>
            <div className='nen' onClick={() => setIsEdit(false)}></div>
            <div className='edit-product'>
              <div className='title-edit'>
                <p>Edit products</p>
              </div>
              <div className='form-edit'>
                <div className='name-product'>
                  <p>Product name</p>
                  <input
                    value={productEdit.name}
                    placeholder='tên sản phẩm'
                    onChange={(e) => handleChanValue(e, 'name')}
                  />
                </div>
                <div className='price'>
                  <p>Product price</p>
                  <input
                    value={productEdit.price}
                    placeholder='giá sản phẩm'
                    onChange={(e) => handleChanValue(e, 'price')}
                  />
                </div>
              </div>
              <div className='btn-confirm d-flex justify-content-center'>
                <button onClick={() => handleEditProduct()}>Confirm</button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div>
        <p>Please add products</p>
      </div>
    )
  }
}

export default ProductList
